body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.brand-logo {
  -webkit-animation: brand-logo-spin infinite 20s linear;
          animation: brand-logo-spin infinite 20s linear;
  height: 30px; }

@-webkit-keyframes brand-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes brand-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.section-header {
  padding: 0.5rem 1rem;
  background-color: #fff176;
  border: solid 1px #ffeb3b; }

.flip-box {
  display: flex;
  align-items: center;
  background-color: transparent;
  width: 100%;
  height: 380px;
  -webkit-perspective: 1000px;
          perspective: 1000px; }

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

.flip-box:hover .flip-box-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.flip-box-back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1019;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px; }
  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px; } }

.sidenav--open {
  width: 250px; }

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 3rem;
  color: #818181;
  display: block;
  transition: 0.3s; }
  @media screen and (max-height: 450px) {
    .sidenav a {
      font-size: 1.8rem; } }

.sidenav a:hover {
  color: #f1f1f1; }

.overlay {
  top: 0;
  display: none;
  position: fixed;
  /* full screen */
  width: 100vw;
  height: 100vh;
  /* transparent black */
  background: rgba(0, 0, 0, 0.7);
  /* middle layer, i.e. appears below the sidebar */
  z-index: 1019 -1;
  opacity: 0;
  /* animate the transition */
  transition: all 0.5s ease-in-out; }

/* display .overlay when it has the .active class */
.overlay--active {
  display: block;
  opacity: 1; }

.font-size-6 {
  font-size: 6rem; }

.pointered {
  cursor: pointer !important; }

.fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

@media screen and (min-width: 1000px) {
  .fit-content {
    width: auto; } }

