@import "./utilities/flip-image.scss";

$break-small-height: 450px;
$sidenav-index: 1019;
$overlay-index: $sidenav-index -1;

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: $sidenav-index;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;

  @media screen and (max-height: $break-small-height) {
    padding-top: 15px;
  }
}

.sidenav--open {
  width: 250px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 3rem;
  color: #818181;
  display: block;
  transition: 0.3s;

  @media screen and (max-height: $break-small-height) {
    font-size: 1.8rem;
  }
}

.sidenav a:hover {
  color: #f1f1f1;
}

.overlay {
  top: 0;
  display: none;
  position: fixed;
  /* full screen */
  width: 100vw;
  height: 100vh;
  /* transparent black */
  background: rgba(0, 0, 0, 0.7);
  /* middle layer, i.e. appears below the sidebar */
  z-index: $overlay-index;
  opacity: 0;
  /* animate the transition */
  transition: all 0.5s ease-in-out;
}
/* display .overlay when it has the .active class */
.overlay--active {
  display: block;
  opacity: 1;
}

.font-size-6 {
  font-size: 6rem;
}

.pointered {
  cursor: pointer !important;
}

.fit-content {
  width: fit-content;
}

@media screen and (min-width: 1000px) {
  .fit-content {
    width: auto;
  }
}

