.brand-logo {
    animation: brand-logo-spin infinite 20s linear;
    height: 30px;
  }
  
  @keyframes brand-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  